import Box from '@mui/material/Box';
import { FC } from 'react';
import AvatarMenu from './AvatarMenu';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';

const UserMenu: FC = () => {
    const { logout } = useAuth();
    const { info: user } = useUser();

    return (
        <Box alignItems="center" display="flex">
            <AvatarMenu
                firstName={user?.firstName ?? ''}
                lastName={user?.lastName ?? ''}
                logout={logout}
            />
        </Box>
    );
};

export default UserMenu;
